import React from 'react';
import { Button, Grid } from '@material-ui/core';

import {
  EntityAboutCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import { EntityMembersListCard } from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';

import {
  EntityCircleCIContent,
  isCircleCIAvailable,
} from '@backstage/plugin-circleci';

import {
  EntityIdpMetadataContent,
  EntityIdpVaultContent,
  EntityIdpMetricsContent,
  EntityIdpRecentlyCostContent,
  EntityIdpRecentlyCostCard,
  EntityIdpExecutorListCard,
  isIdpRecentCostAvailable,
  isIdpResource,
  hasIdpExecutor,
  EntityIdpEcrContent,
  EntityIdpRevisionContent,
  EntityIdpLogContent,
  EntityIdpAboutCard,
  EntityIdpAboutServiceCard,
  EntityIdpKubernetesContent,
  isIdpService,
  isIdpProject,
  EntityIdpTreeCard,
  EntityIdpDependenciesListCard,
  isIdpOrganization,
} from '@internal/plugin-catalog-module-idp';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TeamOwnerCard } from '@internal/plugin-teams';
import { EntityAuditListCard } from '@internal/plugin-audits';
import { ToolsCard } from '@internal/plugin-toolset';
import {
  EntityUserProfileCard,
  EntityAwsAccessCard,
  EntityAwsUserPermissionList,
} from '@internal/backstage-plugin-org-chart';
import { useEntity } from '@backstage/plugin-catalog-react';

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isCircleCIAvailable}>
      <EntityCircleCIContent />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntitySwitch>
        <EntitySwitch.Case if={isIdpResource}>
          <EntityIdpAboutCard variant="gridItem" />
        </EntitySwitch.Case>
        <EntitySwitch.Case if={isIdpService}>
          <EntityIdpAboutServiceCard variant="gridItem" />
        </EntitySwitch.Case>
        <EntitySwitch.Case>
          <EntityAboutCard variant="gridItem" />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityIdpTreeCard />
    </Grid>

    <EntitySwitch>
      <EntitySwitch.Case if={isIdpResource}>
        <Grid item md={6}>
          <EntityIdpDependenciesListCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasIdpExecutor}>
        <Grid item md={12}>
          <EntityIdpExecutorListCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isIdpRecentCostAvailable}>
        <Grid item md={12} xs={12}>
          <EntityIdpRecentlyCostCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    {/* <Grid item md={6} xs={12}>
      <EntityVaultCard />
    </Grid> */}
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/vault" title="Vault">
      <EntityIdpVaultContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ecr" title="ECR">
      <EntityIdpEcrContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/revision" title="Revision">
      <EntityIdpRevisionContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/log" title="Log">
      <EntityIdpLogContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/kubernetes" title="Kubernetes">
      <EntityIdpKubernetesContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/audits" title="Audit">
      <EntityAuditListCard />
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const AwsUserPermissionRoute = () => {
  const { entity } = useEntity();
  const isAwsAccountResource = entity?.spec?.type === 'aws_account';
  return isAwsAccountResource ? <EntityAwsUserPermissionList /> : null;
};

const resourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/metadata" title="Metadata">
      <EntityIdpMetadataContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/metrics" title="Metrics">
      <EntityIdpMetricsContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/cost" title="Cost">
      <EntityIdpRecentlyCostContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/revision" title="Revision">
      <EntityIdpRevisionContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/log" title="Log">
      <EntityIdpLogContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/audits" title="Audit">
      <EntityAuditListCard />
    </EntityLayout.Route>
    <EntityLayout.Route path="/access" title="Access" >
      <AwsUserPermissionRoute />
    </EntityLayout.Route>
  </EntityLayout>
);




const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={12}>
          <EntityUserProfileCard variant="gridItem" showLinks={undefined} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/access" title="Access">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <EntityAwsAccessCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ToolsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/audits" title="Audit">
      <EntityAuditListCard />
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={8}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={4} md={4}>
          <TeamOwnerCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/audits" title="Audit">
      <EntityAuditListCard />
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={12}>
          <EntitySwitch>
            <EntitySwitch.Case if={isIdpProject}>
              <EntityIdpAboutCard variant="gridItem" />
            </EntitySwitch.Case>
            <EntitySwitch.Case if={isIdpOrganization}>
              <EntityIdpAboutCard variant="gridItem" />
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>
        <Grid item md={4}>
          <EntityIdpTreeCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/revision" title="Revision">
      <EntityIdpRevisionContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/log" title="Log">
      <EntityIdpLogContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/audits" title="Audit">
      <EntityAuditListCard />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case if={isKind('resource')} children={resourcePage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
